import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import emailjs from "emailjs-com";
import { console } from "window-or-global";

const SEO = ({
  title,
  description,
  imageSrc,
  imageHeight,
  imageWidth,
  pathname,
  article,
  link = "",
  linkCheck = "",
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage, twitterUsername },
      },
    }) => {
      if (typeof window !== "undefined" && !link) {
        link = window.location.href;
        linkCheck = window.location.pathname;
      }
      function image() {
        if (linkCheck.includes("/list/")) {
          return seo.image;
        } else {
          return seo.imageSrc;
        }
      }
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        imageSrc: linkCheck.includes("/list/") ? imageSrc : `${siteUrl}${defaultImage}`,
        image: imageSrc,
        url: `${siteUrl}${pathname || "/"}`,
        imageHeight,
        imageWidth,
      };
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="og:image" content={seo.imageSrc} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.imageSrc && <meta property="og:image" content={seo.imageSrc} />}
            {seo.imageHeight && <meta property="og:image:height" content={seo.imageHeight} />}
            {seo.imageWidth && <meta property="og:image:width" content={seo.imageWidth} />}
            {seo.image && <meta property="og:image" content={seo.imageSrc} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.imageSrc} />}

            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <html lang="en" />
          </Helmet>
        </>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
